<script setup lang="ts">
import type { AhProduct } from '~/types/models/product/product';
import { ApiClientError } from '@shopware/api-client';

defineOptions({ inheritAttrs: false });
const emits = defineEmits<{
    (e: 'toggle-restriction-message', message?: string | undefined): void;
    (e: 'add-to-cart-success'): void;
}>();

withDefaults(
    defineProps<{
        showRestrictionMessage?: boolean;
        type?: string;
        disabled?: boolean;
    }>(),
    {
        showRestrictionMessage: false,
        type: 'listing',
        disabled: false,
    },
);

const isLoading = ref(false);
const { isLoggedIn } = useUser();
const { product, sharedQuantity, isInvalid, isAvailable } = useQuantityValidation();
const { pushSuccess, pushError } = useNotifications();
const { refreshCart } = useCart();

// @ts-ignore Argument of type 'ComputedRef<Product | Partial<Product> | null>' is not assignable to parameter of type 'Ref<Product>'.
const { addToCart, quantity } = useAddToCart(product);
const { resolveApiErrors } = useApiErrorsResolver('account_login');

const { t } = useI18n();

const { prepareLineItemsEcommerceForTracking } = usePrepareForTracking();
const { trackProduct } = useProductTracking();
const { getCartItemById } = useAhCart();

const addToCartProxy = async () => {
    if (isLoading.value) return;

    isLoading.value = true;
    quantity.value = sharedQuantity.value;

    try {
        const res = await addToCart();
        await refreshCart(res);

        const addedCartItem = getCartItemById(product.value.id);
        if (addedCartItem !== undefined) {
            const trackedCartItem = { ...addedCartItem };
            trackedCartItem.quantity = sharedQuantity.value;

            trackProduct(
                'add_to_cart',
                prepareLineItemsEcommerceForTracking({
                    lineItem: [trackedCartItem],
                    totalPrice: trackedCartItem.price?.totalPrice,
                }),
            );
        }

        const prepErrorArray = Object.values(res.errors);
        const errors = resolveApiErrors(prepErrorArray as unknown as AhApiError[]);

        if (errors?.length > 0) {
            errors?.forEach((message) => pushError(message));
        } else {
            pushSuccess(t('addToCartSuccessAddon', { product: product.value?.name }));
            emits('add-to-cart-success');
        }
    } catch (error) {
        Logger.captureException(error);

        if (error instanceof ApiClientError) {
            const errors = resolveApiErrors(error.details.errors);
            errors.forEach((error) => pushError(error));
        } else {
            pushError(t('errors.message-default'));
        }
    } finally {
        isLoading.value = false;
    }
};

const { getPrice } = useProductPrices();
const priceInfo = computed(() => getPrice(product.value?.productNumber));

const isArchiveArticle = computed(() => (product.value as AhProduct)?.extensions?.attributes.isArchive);
</script>

<template>
    <ClientOnly>
        <div>
            <SharedButton
                v-if="!priceInfo?.unbuyable"
                v-bind="$attrs"
                :disabled="disabled || !isLoggedIn || isInvalid || isArchiveArticle || !isAvailable"
                :background-type="type === 'buybox' ? 'dark' : 'light'"
                color="brand-cart"
                class="w-full"
                :title="$t('addToCartTitle')"
                @click="addToCartProxy"
            >
                <Transition
                    enterActiveClass="transition-opacity"
                    enterFromClass="opacity-0"
                    enterToClass="opacity-100"
                    leaveActiveClass="transition-opacity"
                    leaveFromClass="opacity-100"
                    leaveToClass="opacity-0"
                    mode="out-in"
                >
                    <LazySvgoCart
                        v-if="!isLoading"
                        class="w-4.5 h-auto flex-shrink-0"
                    />
                    <SharedLoader
                        v-else
                        class="!text-gray-100 !fill-brand-cart"
                        size="sm"
                    />
                </Transition>
                <slot name="text" />
            </SharedButton>
            <template v-else>
                <SharedButton
                    v-bind="$attrs"
                    :color="type === 'buybox' ? 'gray-200' : 'gray-300'"
                    class="w-full"
                    :title="$t('addToCartTitle')"
                    @click="
                        emits('toggle-restriction-message', priceInfo?.unbuyableText || $t('productUnbuyableFallback'))
                    "
                >
                    <Transition
                        enterActiveClass="transition-opacity"
                        enterFromClass="opacity-0"
                        enterToClass="opacity-100"
                        leaveActiveClass="transition-opacity"
                        leaveFromClass="opacity-100"
                        leaveToClass="opacity-0"
                        mode="out-in"
                    >
                        <LazySvgoXMark
                            v-if="showRestrictionMessage"
                            class="w-4.5 h-auto"
                            color="white"
                        />
                        <LazySvgoCart
                            v-else
                            class="w-4.5 h-auto flex-shrink-0"
                            color="white"
                        />
                    </Transition>
                    <slot name="text" />
                </SharedButton>
            </template>
        </div>
    </ClientOnly>
</template>
